import React, { Component } from 'react';

/* THIS PAGE HAS BEEN MOVED TO WRITING.HUDL */
export default class CallsToAction extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.___navigate(
      'https://hudl.com/design/writing/writing-for/components/cta-buttons-links'
    );
  }

  render() {
    return <div />;
  }
}
